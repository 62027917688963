export function LI(props) {
    const ss = props.data?.map(
        (item, index) => {
            const link = "#" + item?.Name
            return (
                <li key={index} className="nav-item" ><a className="nav-link js-scroll-trigger" href={link}>{item?.Name}</a></li>
            )
        });

    return (
        <>
            <li key="0" className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#about">About</a>
            </li>
            {ss}
        </>
    );
}