import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Auth } from "./pages/auth/index"
import { Profile } from "./pages/profile/index";
import { AddProfile } from "./pages/addprofile/index";
import { EditProfile } from "./pages/editprofile/index";
import { Tt } from "./pages/tt/index";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Auth />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/addprofile" element={<AddProfile />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/profile/:id" element={<Tt />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
