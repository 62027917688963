export function Items(props) {
    //console.log(props.data)

    const ss = props.data?.map((item, index) =>
        <li key={index}>{item}</li>
    );

    return (
        <ul>{ss}</ul>
    );
}