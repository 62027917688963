import { useEffect, useState } from "react";
import { Attr } from "./Attr";
import { Items } from "./Items";
import EditModal from "./EditSectionModal";
import { AddSection } from "./AddSection";
import { set } from 'firebase/database';

export function Section(props) {
    //console.log("======SECTION============")
    //console.log(props)
    return (<>
        {props.data.Section?.map((section, index) => (
            <>
                {/*console.log("INDEX", index, props.id)*/}
                <div key="100" className="edit-container" >
                    <div key="10" className="topright">
                        <EditModal
                            updateSection={props.updateSection}
                            sid={props.id}
                            id={index}
                            data={section}
                        />
                    </div >
                    <div key="20" className="resume-item d-flex flex-column flex-md-row mb-5">
                        <div key="30" className="resume-content mr-auto">
                            <h3 className="mb-0">{section.Title}   {index}</h3>
                            <div className="subheading mb-3">{section.STitle}</div>

                            <Items data={section.Items} />

                            <b>{section.AttrT}</b><br />
                            <Attr data={section.Attr} />
                        </div >

                        <div key="40" className="resume-date text-md-right">
                            <span className="text-primary">{section.DateS} {section.DateE} </span>
                        </div>
                    </div>
                </div > <br />
            </>
        ))
        }
        <AddSection name={props.name} />
    </>
    );
}
