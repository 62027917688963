import EditModal from "./EditSectionModal";

export function AddSection(props) {
    const id = "cahitoz@gmail.com";
    //console.log(props);

    var newProps = { Title: "New " + props.name + " Entry" }
    return (<>
        <div key="100" className="edit-container" >
            <div key="10" className="topright">
                <EditModal
                    updateSection={props.updateSection}
                    data={newProps}
                />
            </div >
            <div key="20" className="resume-item d-flex flex-column flex-md-row mb-5">
                <div key="30" className="resume-content mr-auto">
                    <h3 className="mb-0">{newProps.Title} </h3>
                    <div className="subheading mb-3">{/*section.STitle*/}</div>

                    {/*<Items data={section.Items} />*/}

                    <b>{/*section.AttrT*/}</b><br />
                    {/*<Attr data={section.Attr} />*/}
                </div >

                <div key="40" className="resume-date text-md-right">
                    <span className="text-primary">{/*section.DateS} {section.DateE*/} </span>
                </div>
            </div>
        </div > <br />
    </>);
}
