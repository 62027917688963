import { db } from "../../config/firebase"
import { useEffect, useState } from "react";

import Button from 'react-bootstrap/Button';
import { getDoc, getDocs, doc, collection, updateDoc, setDoc } from 'firebase/firestore';

import { EditAbout } from "../../Components/EditAbout"
import { Sections } from "../../Components/EditSections";
import { Navbar } from "../../Components/EditNavbar";

export const EditProfile = () => {
    const id = "cahitoz@gmail.com";

    // get userData
    const docRef = doc(db, "profiles", id);
    const [profile, setProfile] = useState([]);
    useEffect(() => {
        const getProfile = async () => {
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    //console.log("docSnap.data", docSnap.data())
                    setProfile(docSnap.data());
                } else {
                    console.log("No document!");
                }
            } catch (err) {
                console.error(err)
            }
        }
        getProfile();
    }, [])

    const updateNav = async (Sections) => {
        console.log(Sections);
        console.log(profile)
        profile.Sections = Sections;

        setProfile(profile);
        console.log(profile)
        await updateDoc(docRef, profile);
    }

    const updateAbout = async (id, newName, newSurname, newAddress, newPhone, newSummary) => {

        console.log("Updated Profile in Parent");
        console.log(id, newName, newSurname, newAddress, newPhone, newSummary);
        console.log(profile)

        profile.Name = newName;
        profile.Surname = newSurname;
        profile.Adress = newAddress;
        profile.Phone = newPhone;   //// MAYBE nOT WORKING
        profile.summary = newSummary;
        console.log(profile)

        //const newFields = { Name: newName, Surname: newSurname, Adress: newAddress, Phone: newPhone }
        await updateDoc(docRef, profile);
    }

    const updateSection = async (sectionid, title) => {
        console.log(profile);
        console.log("Root:", sectionid, title)


    }

    const handleSave = () => alert(11);

    return (
        <>
            <Navbar profile={profile} updateNav={updateNav} />
            <EditAbout data={profile} id={id} updateAbout={updateAbout} />
            <Sections profile={profile} updateSection={updateSection} />

            <Button variant="light" onClick={handleSave}>
                <img id="edit" height="20" src="/save-xxl.png" alt="Save" />
            </Button>
        </>
    );
};