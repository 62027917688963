import { useState } from "react";
import { NameSurname } from "./NameSurname";
import { Address } from "./Address";
import EditModal from "./EditAboutModal";
import { UL } from "./UL";


export function EditAbout(props) {
    const [newName, setnewName] = useState("");
    const [newSurname, setnewSurname] = useState("");
    const [newAddress, setnewAddress] = useState("");
    const [newPhone, setnewPhone] = useState("");
    const [newSummary, setnewSummary] = useState("");

    const updateAbout2 = async (id, newName, newSurname, newAddress, newPhone, newSummary) => {
        console.log(id, newName, newSurname, newAddress, newPhone, newSummary);
        console.log(newSummary);
        setnewName(newName);
        setnewSurname(newSurname);
        setnewAddress(newAddress);
        setnewPhone(newPhone)
        setnewSummary(newSummary);

        const newFields = { Name: newName }

        //wait updateDoc(docRef, newFields);
        props.updateAbout(id, newName, newSurname, newAddress, newPhone, newSummary);
    }

    return (
        <section className="resume-section p-3 p-lg-5 d-flex d-column" id="about">
            <div className="my-auto" id="about">
                <div className="edit-container" >

                    <img className="Prnt_IMG" src="https://lh3.googleusercontent.com/a/ACg8ocIxcFTdQ-jsnL-FdyFaa36FmO1Iz3nKAa2_hUu2LYDvFvpXbSiw=s96-c" width="245" alt="Cahit Öz" />
                    <NameSurname Name={props.data.Name} Surname={props.data.Surname} />
                    <Address Address={props.data.Adress} Phone={props.data.Phone} Email={props.id} />
                    <UL data={props.data.summary} />

                    <div className="topright">
                        <EditModal
                            key={props.id}
                            id={props.id}
                            data={props.data}
                            //updateAbout={props.updateAbout}
                            updateAbout2={updateAbout2}
                        />
                    </div>

                </div>
            </div>
        </section>

    );
}

