import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ArrowDownCircle, ArrowRight, ArrowUpCircle, CheckCircle, XCircle } from 'react-bootstrap-icons';

function EditAboutModal(props) {
    const [email, setEmail] = useState(props.Email);
    useEffect(() => {
        setEmail(props.Email);
    }, [props.Email]);

    const [name, setName] = useState(props.data.Name);
    useEffect(() => {
        setName(props.data.Name);
    }, [props.data.Name]);

    const [surname, setSurname] = useState(props.data.Surname);
    useEffect(() => {
        setSurname(props.data.Surname);
    }, [props.data.Surname]);

    const [address, setAdress] = useState(props.data.Adress);
    useEffect(() => {
        setAdress(props.data.Adress);
    }, [props.data.Adress]);

    const [phone, setPhone] = useState(props.data.Phone);
    useEffect(() => {
        setPhone(props.data.Phone);
    }, [props.data.Phone]);

    const [summary, setSummary] = useState(props.data.summary);
    useEffect(() => {
        setSummary(props.data.summary);
    }, [props.data.summary]);

    const [summaryItem, setSummaryItem] = useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button variant="light" onClick={handleShow}>
                <img id="edit" height="20" src="/pen.png" alt="Edit" />
            </Button>

            <form id="editModal" onSubmit={(e) => {
                e.preventDefault();
                console.log("UpdateAbout form Submitted")
                //props.updateAbout(props.id, name, surname);
            }}>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit your data...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mb-3">
                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name:</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputName" value={name} onChange={e => setName(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputSurname" className="col-sm-2 col-form-label">Surname</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputSurname" value={surname} onChange={e => setSurname(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="inputAddress" className="col-sm-2 col-form-label">Address</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputAddress" value={address} onChange={e => setAdress(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputPhone" className="col-sm-2 col-form-label">Phone:</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputPhone" value={phone} onChange={e => setPhone(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="inputSummary" className="col-sm-2 col-form-label">Summary:</label>
                            <div className="col-sm-10">
                                <ul>

                                    <Summary Summary={summary} />
                                    <li>
                                        <div className="row align-items-start">
                                            <div className="col-sm-10" ><input type="text" className="form-control" id="inputSummary" value={summaryItem} onChange={e => setSummaryItem(e.target.value)} /></div>
                                            <div className="col-sm-1"><Button variant="light" onClick={() => {
                                                addSummary(summaryItem);
                                            }}>
                                                <CheckCircle color="Green" size={24} /></Button></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="secondary" form="editModal" data-toggle="tooltip" data-placement="top" title="Tooltip on top" onClick={() => {
                            //props.updateAbout(id, name, surname, adress);
                            props.updateAbout2(email, name, surname, address, phone, summary);
                            handleClose();
                        }}>
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>
            </form>
        </>
    );


    function deleteSummary(k) {
        const csummary = [...summary];
        const any = csummary.splice(k, 1);
        setSummary(csummary);
    }

    function addSummary(e) {
        if (e == "") return;
        const csummary = [...summary];
        console.log(e);
        csummary.push(e);
        //const any = csummary.splice(k, 1);
        setSummary(csummary);
        setSummaryItem("");
    }

    function Summary(props) {
        var cnt = 0;
        var idName, cntStr;
        return (
            <>
                {
                    props.Summary.map(function (i, k) {
                        return (
                            <li key={"li" + i}>
                                <div className="row align-items-start">
                                    <div className="col-sm-10" key={i}>{i}</div>
                                    <div className="col-sm-1">
                                        <Button variant="light" onClick={() => {
                                            deleteSummary(k);
                                        }}>
                                            <XCircle color="Red" size={24} />
                                        </Button>
                                    </div>

                                </div>
                            </li>
                        )
                    })}
            </>
        );
    }
}

export default EditAboutModal
