import { useState, useEffect } from 'react';
import EditModal from "./EditNavModal";

import { LI } from "./LI";


export function Navbar(props) {
    const SecTitle = [];
    console.log(props.profile.Sections);

    const [sectionTitles, setSectionTitles] = useState([props.profile.Sections]);
    useEffect(() => {
        setSectionTitles(props.profile.Sections)
    }, [props.profile.Sections])


    const id = "cahitoz@gmail.com";

    const pimage = process.env.PUBLIC_URL + "/profile.jpg";

    //https://lh3.googleusercontent.com/a/ACg8ocIxcFTdQ-jsnL-FdyFaa36FmO1Iz3nKAa2_hUu2LYDvFvpXbSiw=s96-c
    return (

        <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
                <span className="d-block d-lg-none">Cahit Öz</span>
                <span className="d-none d-lg-block">
                    <img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={pimage} alt="Profile Picture" />
                </span>
            </a>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <LI data={sectionTitles} />
                    </ul>

                </div>
            </div>
        </nav >
    );
}
