import MailLink from "./MailLink";
import { UL } from "./UL";

export default function About(props) {
    return (
        <section className="resume-section p-3 p-lg-5 d-flex d-column" id="about">
            <div className="my-auto" id="about">
                <img className="Prnt_IMG" src="https://lh3.googleusercontent.com/a/ACg8ocIxcFTdQ-jsnL-FdyFaa36FmO1Iz3nKAa2_hUu2LYDvFvpXbSiw=s96-c" width="245" alt="Cahit Öz" />

                <h1 className="mb-0 P1">{props.data.Name}&nbsp;
                    <span className="text-primary P1">{props.data.Surname}</span>
                </h1>

                <div className="subheading mb-5">{props.data.Adress}<br />yyy
                    <MailLink data={props.data.Email} />&nbsp;{props.data.Phone}
                </div>

                <UL data={props.data.summary} />

            </div>
        </section>
    );
}