import { auth, provider } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";

//import { useAddProfile } from '../../hooks/useAddProfile'
import { useEffect, useState } from "react";

import { db } from "../../config/firebase"
import { getDoc, getDocs, doc, collection } from 'firebase/firestore';

export const Tt = () => {
    const { id } = useParams()

    const profilesCollectionRef = collection(db, "profiles", id, "Selection")
    const docRef = doc(db, "profiles", id);

    const [profile, setProfile] = useState([]);
    //const getProfile = "";

    //const { addProfile } = addProfile();
    //*
    const onAdd = (e) => {
        e.preventDefault();
        //addProfile({ userID: "cahitoz", description: "HELPPPP" });
    }//*/

    useEffect(() => {
        console.log("useEffect");
        const getProfile = async () => {
            //Read date
            //Set data
            try {

                /*****************************************/
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    setProfile(docSnap.data());
                } else {
                    // docSnap.data() will be undefined in this case
                    console.log("No such document!");
                }
                /**************************************/
                const data = await getDocs(profilesCollectionRef);
                console.log("Selection Data:", data);



                const filteredData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                console.log("Filtered Data:", filteredData);

            } catch (err) {
                console.error(err)
            }
        }
        getProfile();
    }, [])

    console.log("Show About");
    return (
        <section className="resume-section p-3 p-lg-5 d-flex d-column" id="about">
            <div className="my-auto">
                <img className="Prnt_IMG" src="https://lh3.googleusercontent.com/a/ACg8ocIxcFTdQ-jsnL-FdyFaa36FmO1Iz3nKAa2_hUu2LYDvFvpXbSiw=s96-c" width="245" alt="Cahit Öz" />

                <h1 className="mb-0 P1">{profile.Name}&nbsp;
                    <span className="text-primary P1">{profile.Surname}</span>
                </h1>

                <div className="subheading mb-5">{profile.Adress}<br />
                    <a href='"mailto:"{profile.Email}'>{profile.Email}</a>&nbsp;
                    {profile.Phone}
                </div>


                <ul>
                    <li>20+ years of work experience of which 12+ years were involved in coordinating and integrating teams located in geographically separated locations.</li>
                    <li>Ensuring that the infrastructure and software solution meets the required security standards and regulations (hardening). Vulberability Scanning DevSecOps</li>
                    <li>Implementation of HA (High Availability) systems that specified DR solutions considering cost and RTO/RPO.</li>
                    <li>Containerizing applications and their orchestration using Kubernetes / EKS / ECS</li>
                    <li>Requirements management, documentation and optimization of processes.</li>
                    <li>A Cloud enthusiast with experience in AWS. Azure and GCloud and platform independant tools like Terraform, Kubernetes, Packer and Docker</li>
                </ul>
            </div>
        </section>


    );
}; 
