import { Section } from "./EditSection";

export function Sections(props) {
    //console.log(props.profile.Sections)

    var retStr = "";

    /*const selections = [];
    const selMap = new Map();
    props.data.forEach((doc) => {
        selections.push(doc.data().Sec_Title);
        selMap.set(doc.data().Sec_Title, doc.id)

    })*/

    const ss = props?.profile?.Sections?.map(
        (item, index) => {
            return (
                <section key={item + index} className='resume-section p-3 p-lg-5 d-flex d-column' id={item.Name}>
                    <div className="my-auto">
                        <h2 className='mb-5 P2'>{item.Name}</h2><p />
                        <Section key={index} id={index} data={item} name={item.Name} updateSection={props.updateSection} />
                    </div>
                </section>
            );

        });

    return (
        <>
            {ss}
        </>
    );// */
}