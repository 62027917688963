export function NameSurname(props) {

    if (props.Name === "") {
        return (<h1 className="mb-0 P1">Yourname&nbsp;
            <span className="text-primary P1">Surname</span>
        </h1>)
    } else {
        return (
            <h1 className="mb-0 P1">{props.Name}&nbsp;
                <span className="text-primary P1">{props.Surname}</span>
            </h1>
        );
    }
}