import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ArrowDownCircle, ArrowRight, ArrowUpCircle, CheckCircle, XCircle } from 'react-bootstrap-icons';
//import { Array } from './EditArray';

function EditNavModal(props) {
    //console.log(props.data);
    const [email, setEmail] = useState(props.Email);
    useEffect(() => {
        setEmail(props.Email);
    }, [props.Email]);

    const [sections, setSections] = useState(props.data);
    useEffect(() => {
        //console.log("============================");
        console.log(props.data);
        //console.log("============================");
        setSections(props.data);
    }, [props.data]);


    const [section, setSection] = useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSave = () => alert(11);
    return (
        <>
            <Button variant="light" onClick={handleShow}>
                <img id="edit" height="20" src="/pen.png" alt="Edit" />
            </Button>

            <form id="editModal" onSubmit={(e) => {
                e.preventDefault();
                console.log("UpdateAbout form Submitted")
                //props.updateAbout(props.id, name, surname);

            }}>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Navigation/Sections...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <NavTitles data={sections} />


                        <div className="row align-items-start">
                            <div className="col-sm-10" ><input type="text" className="form-control" id="inputSummary" value={section} onChange={e => setSection(e.target.value)} /></div>
                            <div className="col-sm-1"><Button variant="light" onClick={() => {
                                addSection(section);
                            }}>
                                <CheckCircle color="Green" size={24} /></Button></div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="secondary" form="editModal" onClick={() => {
                            props.updateNav(sections);

                            handleClose();
                        }}>
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>
            </form >
        </>
    );

    function deleteSection(k) {
        //console.log(k);

        const csections = [...sections];
        const any = csections.splice(k, 1);
        setSections(csections);
    }

    function NavTitles(props) {
        const items = props.data;
        //console.log(items);
        return (
            <>

                {items?.map((i, k) => (
                    <>
                        <div key={"0" + i} className="row align-items-start">
                            <div className="col-sm-10" key={"1" + i}>{i.Name}</div>
                            <div className="col-sm-1" key={"2" + i}>
                                <Button variant="light" onClick={() => {
                                    deleteSection(k);
                                }}>
                                    <XCircle color="Red" size={24} />
                                </Button>
                            </div>

                        </div>
                    </>

                ))}

            </>
        );
    }

    function EditSection(props) {

    }


    function addSection(e) {
        if (e == "") return;
        const csections = [...sections];
        csections.push({ Name: e });
        setSections(csections);
        setSection("");
    }
}

export default EditNavModal

