import { Section } from "./Section";

export function Sections(props) {
    console.log(props);
    var retStr = "";
    const selections = [];
    const selMap = new Map();
    props.data.forEach((doc) => {
        selections.push(doc.data().Sec_Title);
        selMap.set(doc.data().Sec_Title, doc.id)
    })

    const ss = selections.map(
        (item, index) => {
            return (
                <section key={item + index} className='resume-section p-3 p-lg-5 d-flex d-column' id={item}>
                    <div className="my-auto">
                        <h2 className='mb-5 P2'>{item}</h2><p />
                        <Section key={index + item} data={selMap.get(item)} />
                    </div>
                </section>
            )

        });

    return (
        <>
            {ss}
        </>
    );
}