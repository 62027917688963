import { getDoc, getDocs, doc, collection } from 'firebase/firestore';
import { useEffect, useState } from "react";
import { db } from "../config/firebase"
import { Attr } from "./Attr";
import { Items } from "./Items";

export function Section(props) {
    const id = "cahitoz@gmail.com";
    const profileSubSelectionCollectionRef = collection(db, "profiles", id, "Selection", props.data, "SSec");

    const [ssection, setssection] = useState([]);
    useEffect(() => {
        const getssection = async () => {
            try {
                const docSnap = await getDocs(profileSubSelectionCollectionRef);
                setssection(docSnap);
            } catch (err) {
                console.error(err)
            }
        }
        getssection();
    }, [])

    const ssel = [];
    const sselMap = new Map();
    ssection.forEach((doc) => {
        ssel.push(doc.id);
        sselMap.set(doc.id, doc.data());
    });

    const ss = ssel.map(
        (item, index) => {
            const json = sselMap.get(item);
            return (<>
                <div className="resume-item d-flex flex-column flex-md-row mb-5">
                    <div className="resume-content mr-auto">
                        <h3 className="mb-0">{json.Title}</h3>
                        <div className="subheading mb-3">{json.STitle}</div>
                        <Items data={json.Items} />

                        <b>{json.AttrT}</b><br />
                        <Attr data={json.Attr} />

                    </div>
                    <div className="resume-date text-md-right">
                        <span className="text-primary">{json.DateS} {json.DateE} </span>
                    </div>
                </div><br />
            </>)
        });

    return (
        <>
            {ss}
        </ >

    );
}
