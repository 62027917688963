import { auth, provider } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const Auth = () => {
    const navigate = useNavigate();

    const signInWithGoogle = async () => {
        const result = await signInWithPopup(auth, provider);


        const authInfo = {
            userID: result.user.uid,
            name: result.user.displayName,
            profilePhoto: result.user.photoURL,
            isAuth: true,

        };
        localStorage.setItem("auth", JSON.stringify(authInfo));
        console.log(result);

        navigate("/profile");
    }

    return (
        <div className="login-page">
            <p>Sign in with Goolgle</p>
            <button className="login-with-google-btn" onClick={signInWithGoogle}>
                {" "}
                Sign in with Google
            </button>
        </div>
    );
};