import MailLink from "./MailLink"

export function Address(props) {
    if (props.Address === undefined) {
        return (
            <>
            </>
        )
    } else {
        return (
            <div className="subheading mb-5">{props.Address}<br />
                <MailLink Email={props.Email} />&nbsp;{props.Phone}
            </div>
        );
    }
}