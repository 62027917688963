import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Array } from './EditArray';

function EditModal(props) {
    //console.log(props.data)

    const [title, setTitle] = useState(props?.data.Title);
    useEffect(() => {
        setTitle(props.data.Title);
    }, [props.data.Title]);

    const [stitle, setSTitle] = useState(props.data.STitle);
    useEffect(() => {
        setSTitle(props.data.STitle);
    }, [props.data.STitle]);

    const [attrT, setAttrT] = useState(props.data.AttrT);
    useEffect(() => {
        setAttrT(props.data.AttrT);
    }, [props.data.AttrT]);

    const [dateE, setDateE] = useState(props.data.DateE);
    useEffect(() => {
        setDateE(props.data.DateE);
    }, [props.data.DateE]);

    const [dateS, setDateS] = useState(props.data.DateS);
    useEffect(() => {
        setDateS(props.data.DateS);
    }, [props.data.DateS]);

    //******************************************* */

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>

            <Button variant="light" onClick={handleShow}>
                <img id="edit" height="20" src="/pen.png" alt="Edit" />
            </Button>

            <form id="editModal" onSubmit={(e) => {
                e.preventDefault();
                console.log("UpdateAbout form Submitted")
                //props.updateAbout(props.id, name, surname);
            }}>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit your data...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mb-3">
                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Title:</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputName" value={title} onChange={e => setTitle(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputSurname" className="col-sm-2 col-form-label">Sub Title</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputSurname" value={stitle} onChange={e => setSTitle(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="inputSurname" className="col-sm-2 col-form-label">Date From</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputSurname" value={dateS} onChange={e => setDateS(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="inputSurname" className="col-sm-2 col-form-label">Date To</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputSurname" value={dateE} onChange={e => setDateE(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputSurname" className="col-sm-2 col-form-label">Items</label>
                            <div className="col-sm-10">
                                <Array data={props.data.Items} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputAddress" className="col-sm-2 col-form-label">Attribute Title</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputAddress" value={attrT} onChange={e => setAttrT(e.target.value)} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputPhone" className="col-sm-2 col-form-label">Attr:</label>
                            <div className="col-sm-10">
                                <Array data={props.data.Attr} />
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="secondary" form="editModal" onClick={() => {
                            props.updateSection(props.id, title, stitle, dateE, dateS);
                            //console.log("props:", props.id, "section", props.sid, title, stitle, dateE, dateS);
                            //console.log(props.id);
                            handleClose();
                        }}>
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>
            </form>
        </>
    );
}

export default EditModal
