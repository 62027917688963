import { useEffect } from "react";
import { db } from "../../config/firebase"
import { getDoc, getDocs, doc, collection } from 'firebase/firestore';

//import { EditAbout } from "../../Pagefunctions"
import { EditAbout } from "../../Components/EditAbout";
//import { Navbar } from "../../Pagefunctions"
//import EditEmployee from "../../Components/EditEmployee";


export const AddProfile = () => {
    return (
        <div className="profile">
            <div className="container">
                <EditAbout />

            </div>
        </div>
    );
};