export function Array(props) {
    const ul = props.data;

    return (
        <ul>
            {ul?.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
}