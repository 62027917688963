//import { Navbar } from "../../Pagefunctions"
//import { Sections } from "../../Pagefunctions"

import { db } from "../../config/firebase"
import { useEffect, useState } from "react";
import { getDoc, getDocs, doc, collection } from 'firebase/firestore';

import About from "../../Components/About";
import { Sections } from "../../Components/Sections";
import { Navbar } from "../../Components/Navbar";

export const Profile = () => {
    //const { id } = useParams()
    const id = "cahitoz@gmail.com";

    const docRef = doc(db, "profiles", id);
    const profileSelectionCollectionRef = collection(db, "profiles", id, "Selection");

    //profiles/cahitoz@gmail.com/Selection/HcoKr24vO4DqKTKoas6y/SSec/7VApKGpFlM1ehlE48rpv
    const [profile, setProfile] = useState([]);
    useEffect(() => {
        const getProfile = async () => {
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setProfile(docSnap.data());
                } else {
                    console.log("No document!");
                }
            } catch (err) {
                console.error(err)
            }
        }
        getProfile();
    }, [])

    const [sections, setSections] = useState([]);
    useEffect(() => {
        const getSections = async () => {
            try {
                const querySnapshot = await getDocs(profileSelectionCollectionRef);
                setSections(querySnapshot);
            } catch (err) {
                console.error(err)
            }
        }
        getSections();
    }, [])

    return (
        <>
            <Navbar data={sections} profile={profile} />
            <About data={profile} />
            <Sections data={sections} />
        </>
    );
};