export default function MailLink(props) {
    if (props.Email === undefined) {
        return (
            <></>
        );
    } else {
        const mailto = "mailto:" + props.Email

        return (
            <a href={mailto}>{props.Email}</a>
        );
    }
}